{
  "iso": "ru_RU",
  "meta": {
    "title": {
      "internalWallet": "Это внутренний кошелек",
      "externalWallet": "Это внешний кошелек",
      "2fa": "Двухфакторная аутентификация защищает твой аккаунт",
      "rating": "Лидеры по доходу",
      "refund": "Гарантия возврата"
    }
  },
  "internalWallet": {
    "title1": "Это внутренний кошелек",
    "text1": "Сюда будет начисляться доход",
    "title2": "С его помощью ты можешь:",
    "list1": [
      {
        "src": "icon-wallet.svg",
        "alt": "Icon wallet",
        "text": "Переводить LEE, CHEEL и USDT во внешний кошелек. При переводе будет списываться комиссия"
      },
      {
        "src": "icon-history.svg",
        "alt": "Icon history",
        "text": "Продавать LEE за фиатную валюту"
      },
      {
        "src": "icon-buy.svg",
        "alt": "Icon buy",
        "text": "Оплачивать покупки внутри приложения"
      }
    ],
    "text2": "Во внутреннем кошельке возможны операции с токенами:",
    "list2": [
      {
        "src": "icon-cheel.png",
        "alt": "Icon cheel",
        "text": "CHEEL"
      },
      {
        "src": "icon-lee.png",
        "alt": "Icon lee",
        "text": "LEE"
      },
      {
        "src": "icon-usdt.png",
        "alt": "Icon usdt",
        "text": "USDT"
      }
    ],
    "text3": "Монету EASY можно будет вывести или продать, как только она появится на биржах. Жди эту новость в нашем community!",
    "button": {
      "text": "Подробнее",
      "link": "https://intercom.help/cheelee/ru/articles/8973167-%D0%BA%D0%B0%D0%BA-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%BD%D0%B8%D0%B9-%D0%BA%D0%BE%D1%88%D0%B5%D0%BB%D0%B5%D0%BA"
    }
  },
  "externalWallet": {
    "title1": "Это внешний кошелек",
    "text1": "Сюда можно переводить монеты из внутреннего кошелька Cheelee и со сторонних криптокошельков",
    "title2": "Используй его, чтобы:",
    "list1": [
      {
        "src": "icon-arrow-diagonal-out.svg",
        "alt": "Icon wallet",
        "text": "Выводить доход из приложения"
      },
      {
        "src": "icon-arrow-diagonal-in.svg",
        "alt": "Icon refill",
        "text": "Пополнять баланс криптовалютой с других сервисов"
      },
      {
        "src": "icon-arrow-round-back.svg",
        "alt": "Icon history",
        "text": "Проверять историю транзакций"
      }
    ],
    "text2": "При переводе монет из внутреннего кошелька и выводе средств взимается комиссия в BNB",
    "list2": [
      {
        "src": "icon-bnb.svg",
        "alt": "Icon bnb",
        "text": "BNB"
      }
    ],
    "button": {
      "text": "Подробнее",
      "link": "https://intercom.help/cheelee/ru/articles/8973315-%D0%BA%D0%B0%D0%BA-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D1%8C-%D0%B2%D0%BD%D0%B5%D1%88%D0%BD%D0%B8%D0%B9-%D0%BA%D0%BE%D1%88%D0%B5%D0%BB%D0%B5%D0%BA"
    }
  },
  "2fa": {
    "title1": "Двухфакторная аутентификация защищает твой аккаунт",
    "text": "При входе в аккаунт с неизвестного браузера&nbsp;или устройства мы будем запрашивать пароль",
    "title2": "Как включить?",
    "list": [
      {
        "src": "img-webview-5-1-ru.png",
        "srcset": "img-webview-5-1-ru@2x.png",
        "alt": "Icon",
        "text": "1. Нажми <b>Профиль</b> в правом нижнем углу экрана"
      },
      {
        "src": "img-webview-5-2.png",
        "srcset": "img-webview-5-2@2x.png",
        "alt": "Icon",
        "text": "2. Нажми на значок в правом верхнем углу экрана и перейди в <b>Настройки</b>"
      },
      {
        "src": "img-webview-5-3-ru.png",
        "srcset": "img-webview-5-3-ru@2x.png",
        "alt": "Icon",
        "text": "3. Выбери <b>Безопасность</b>"
      },
      {
        "src": "img-webview-5-4-ru.png",
        "srcset": "img-webview-5-4-ru@2x.png",
        "alt": "Icon",
        "text": "4. Включи <b>Двухфакторную аутентификацию</b>"
      },
      {
        "text": "5. Следуй инструкциям на экране"
      }
    ]
  },
  "rating": {
    "text1": "Доход",
    "button": "Зарабатывай так же!"
  },
  "refund": {
    "title": "ГАРАНТИЯ ВОЗВРАТА",
    "text1": "Если очки не окупятся за 28 дней — мы вернем тебе в 2 раза больше!",
    "listTitle": "Условия возврата:",
    "text2": "Внимание! Гарантия не действует для очков редкостей FORTUNE, THRILLING, TRUMP, RISKY, PREMIUM",
    "list": [
      {
        "text": "Если очки или кейс приобретены начиная с 26 апреля 2024"
      },
      {
        "text": "Если очки/кейс были куплены не за монеты LEE"
      },
      {
        "text": "Если на аккаунте нет метки «подозрительная активность»"
      },
      {
        "text": "Если 4 недели подряд полностью тратились минуты платного просмотра (дни тех. сбоев не учитываем)"
      }
    ],
    "text3": "Возврат будет в течение 14 рабочих дней, на внутренний кошелек, в USDT. В 2 раза больше, чем цена очков или кейса, за вычетом заработанных монет. Перед начислением очки будут сломаны.",
    "button": "Оформить возврат"
  },
  "lottery": {
    "chances": {
      "title": "твои шансы"
    },
    "offer": {
      "title1": "Розыгрыш активен c:",
      "title": "$$$ или тачка? <br>Получи крутые призы от Cheelee",
      "text": "Совершай покупки, собирай шансы и выигрывай!"
    },
    "steps": [
      {
        "title": "Шаг 1",
        "text1": "Покупай очки и лутбоксы. Получай Шансы для участия в розыгрыше",
        "text2": "<b>Чем круче очки и лутбоксы — тем больше Шансов выиграть!</b>",
        "button": "на маркет"
      },
      {
        "title": "Шаг 2",
        "text1": "Зарегистрируй покупку, чтобы <b>начислить Шансы*</b>",
        "text2": "Наш менеджер <b>подтвердит покупку и начислит твои Шансы</b>",
        "button": "здесь"
      },
      {
        "title": "Шаг 3",
        "text1": "Вступай в Telegram-community Cheelee!",
        "text2": "<b>Следи за новостями и участвуй в интерактивах</b>",
        "button": "присоединиться"
      },
      {
        "title": "Как будут выбраны победители?",
        "text1": "Победителей определят случайным образом и назовут в прямом эфире!",
        "text2": "Список победителей будет опубликован в приложении",
        "button": "Полные правила акции"
      }
    ]
  }
}
